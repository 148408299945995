import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import salespic from "../../images/Blubook.png";
import cmspic from "../../images/Atlas-CMS.png";
import accountpic from "../../images/Smart-Acc.png";
import hospitalHub from "../../images/Hospital-Hub.png";
import "./Products.m.css";

const Products = () => {
  return (
    <div className="product-area" id="products">
      <div className="header">
        <h5>OUR PRODUCTS</h5>
        <h2>Products For business solution</h2>
      </div>
      <div>
        <div className="products">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row justify-content-center g-5">
                  <div className="col-12  col-sm-6 col-lg-3 ">
                    <img className="w-100" alt="ideahub" src={salespic}></img>
                    <div className="products-content">
                      <h2>Blubook</h2>
                      <p>
                        Blubook is your ultimate sales companion. From managing
                        sales and advance sales to generating insightful
                        reports, it streamlines sales process for optimized
                        performance.
                      </p>
                      <div className="see-more">
                        <Link to="/myBlubook">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12  col-sm-6 col-lg-3 ">
                    <img className="w-100" alt="ideahub" src={cmspic}></img>
                    <div className="products-content">
                      <h2>Atlas CMS</h2>
                      <p>
                        The most versatile CMS (Content Management System) for
                        future market adaptation. With ATLAS' futuristic
                        capabilities, you may ease and look forward to the
                        future.
                      </p>
                      <div className="see-more">
                        <Link to="/atlas-cms">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12  col-sm-6 col-lg-3 ">
                    <img className="w-100" alt="ideahub" src={accountpic}></img>
                    <div className="products-content">
                      <h2>Smart Account</h2>
                      <p>
                        Smart Accounts revolutionizes inventory and account
                        management. Experience the power of intuitive software
                        that streamlines inventory tracking and financial
                        management.
                      </p>
                      <div className="see-more">
                        <Link to="/smart-account">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12  col-sm-6 col-lg-3 ">
                    <img
                      className="w-100"
                      alt="ideahub"
                      src={hospitalHub}
                    ></img>
                    <div className="products-content">
                      <h2>HospitalHub</h2>
                      <p>
                        HospitalHub is the ultimate management solution for
                        healthcare facilities. With advanced features tailored
                        for hospitals, it transforms operations and elevates
                        patient care.
                      </p>
                      <div className="see-more">
                        <Link to="/hospitalhub">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
