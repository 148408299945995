import React from "react";
import "./Footer.m.css";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import imoIcon from "../../images/footer-imo.png";
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-area mb-4" id="contact">
      <div className="container">
        <div className="row row-gap-4 ">
          <div className=" col-12 col-md-4">
            <h4>Address</h4>
            <div className="address">
              {/* <div className="add-1 mb-4">
                <h5>Dhaka Office</h5>
                <p>House#15, Road#7, Gulshan-1 Circle, Dhaka 1212</p>
                <li className="d-md-none">
                  <FontAwesomeIcon icon={faEnvelope} /> info@ideahubbd.com
                </li>
              </div> */}
              <div className="add-2">
                <h5>Dhaka Office</h5>
                <p>Ground Floor, House-15, Road-8, Gulshan-1, Dhaka-1212</p>
                <li className="d-md-none">
                  <FontAwesomeIcon icon={faMobileScreen} /> (+880) 196 7921081

                </li>
              </div>
            </div>
          </div>
          {/* <div className='col-md-3 footer-service'>
                        <h4>Service</h4>
                        <ul>
                            <li><Link to="#">Mobile Apps</Link></li>
                            <li><Link to="#">Custom Development</Link></li>
                            <li><Link to="#">UI/UX Design</Link></li>
                            <li><Link to="#">Reachibility</Link></li>
                            <li><Link to="#">SEO</Link></li>
                            <li><Link to="#">BI Dashboard</Link></li>
                        </ul>
                    </div> */}

          <div className=" col-12 col-md-4 useful-link">
            <h4>Useful Link</h4>
            <div className="d-flex flex-row column-gap-3">
              <ul>
                <li>
                  <Nav.Link href="/#home">Home</Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/#service">Service</Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/#about">About</Nav.Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Nav.Link href="/#products">Products</Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                </li>
              </ul>
            </div>
          </div>
          <div className=" col-12 col-md-4 footer-social">
            <h4>Social Link</h4>
            <span className="footer-social-icon">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/ideahubbdofficial"
              >
                {" "}
                <FontAwesomeIcon className="facebook" icon={faFacebook} size="xl" />{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/ideahub-bd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FontAwesomeIcon className="linkedin" icon={faLinkedin} size="xl" />{" "}
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=8801967921081"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FontAwesomeIcon className="whatsapp" icon={faWhatsapp} size="xl" />{" "}
              </a>
              <a
                href="https://s.imoim.net/8w7noE"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img alt="ideahub" className="imoIcon" src={imoIcon} size="xl"></img>
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="main-footer text-center">
        <div className="container">
          <p>Copyright © idea hub {currentYear}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
