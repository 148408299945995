import React from "react";

import "./MyBluBook.m.css";
import { Link } from "react-router-dom";
import blubook_dashboard from "../../images/Detail_Page_Images/Dashboard.png";
import blubook_company from "../../images/Detail_Page_Images/blubook-company.jpg";
import blubook_customer from "../../images/Detail_Page_Images/blubook-customer.jpg";
import blubook_report from "../../images/Detail_Page_Images/blubook-report.jpg";
import blubook_subscription from "../../images/Detail_Page_Images/blubook-subscription.jpg";
import blubook_transaction from "../../images/Detail_Page_Images/blubook-transaction.jpg";
import blubook_transfer from "../../images/Detail_Page_Images/blubook-transfer.jpg";
import blubook_admin from "../../images/Detail_Page_Images/blubook-admin.jpg";
import linstarLogo from "../../images/clientsLogo/linstar-logo.png";
import randomLogo from "../../images/clientsLogo/178538474_450859356827553_4952970996603323508_n (1).jpg";

import { TbBusinessplan } from "react-icons/tb";
import { MdAddBusiness } from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const MyBluBook = () => {
  return (
    <div className="myblubook">
      <ScrollToTop></ScrollToTop>
      <Helmet>
        <title>myBlubook - Ideahub</title>
      </Helmet>
      <div className="smart-accounts-details">
        <div className="Breadcrumb">
          <div>
            <h1>Blubook</h1>
            <p>
              <Link to="/#products">Products / </Link>myBlubook
            </p>
          </div>
        </div>
        <div className="description-mybluebook">
          <div className="container">
            {/* <div className="row align-items-center">
              <div className="col-md-5">
                <img alt="ideahub" src={agileSales}></img>
              </div>
              <div className="col-md-7">
                <h1>myBlubook - All in one Solution</h1>
                <p>
                  Blubook is your ultimate sales companion. From managing sales
                  and inventory to generating insightful reports. It facilitates
                  the operation of your storefront ensuring portability. It
                  ensures portability of your storefront operation as well as
                  customer relationship
                </p>
                <h4>Features:</h4>
                <ul>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> From BOQ to Payment.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Purchase and Stock
                    Management.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Cash management.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Ledger and Profit
                    loss.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Accountably from
                    lower to higher level.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Dashboard for
                    management to monitor project progress according to goal.
                  </li>
                </ul>
              </div>
            </div> */}
            <div>
              <span className="header-text-blubook fw-bold">
                Your All-in-One Business Account Management Solution
              </span>
              <div className="row">
                <p className="mt-2">
                  Are you tired of struggling with complex spreadsheets and
                  disorganized account management for your business? Say hello
                  to Blubook, your all-in-one accounts management solution.
                  Blubook offers a comprehensive set of features to streamline
                  your business operations, making managing your finances a
                  breeze.
                </p>
              </div>
            </div>

            <div className="mt-5">
              <span className="sub-header-text-blubook fw-bold">
                Why Blubook Stands Out as Your Preferred Account Management
                Solution
              </span>
              <div className="row mt-5">
                <div className="col-12 col-md-6 col-lg-4 side-border ">
                  <div className="px-3">
                    <div className="d-flex flex-row column-gap-3 align-items-center">
                      <TbBusinessplan
                        className="mb-3"
                        size={40}
                        color="#5f70ff"
                      ></TbBusinessplan>
                      <h4 className="">Comprehensive Financial Management</h4>
                    </div>
                    <p className="fs-5">
                      A financial chart with upward trending lines or a
                      calculator and a stack of coins to symbolize financial
                      management.
                    </p>
                    <p className="fs-5">
                      From tracking sales and expenses to managing stock
                      transfers and customer payments, Blubook offers an
                      all-in-one solution for your accounting needs, freeing up
                      your time for business growth.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 side-border">
                  <div className="px-3">
                    <div className="d-flex flex-row column-gap-3 align-items-center">
                      <RiTeamFill
                        className="mb-3"
                        size={40}
                        color="#5f70ff"
                      ></RiTeamFill>
                      <h4 className="">Effortless Team Collaboration </h4>
                    </div>
                    <p className="fs-5">
                      A group of people working together, possibly in
                      silhouette, or a collaboration symbol like hands shaking
                      to represent teamwork and cooperation.
                    </p>
                    <p className="fs-5">
                      With user role settings, you can easily invite team
                      members and accountants to collaborate, enabling them to
                      access financial data, generate reports, and manage
                      accounts efficiently.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 ">
                  <div className="px-3">
                    <div className="d-flex flex-row column-gap-3 align-items-center">
                      <MdAddBusiness
                        className="mb-3"
                        size={40}
                        color="#5f70ff"
                      ></MdAddBusiness>
                      <h4 className="">Integrated Business Platform </h4>
                    </div>

                    <p className="fs-5 ">
                      A puzzle piece fitting into a larger puzzle, a collection
                      of interconnected gears, or a versatile toolbox
                      representing various tools and functions coming together.
                    </p>
                    <p className="fs-5">
                      As your business expands, Blubook seamlessly integrates
                      with other business tools, providing you access to a wide
                      range of features to manage and streamline every aspect of
                      your business, all in one place.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="row row-gap-5">
                <div className="  col-12 col-md-6 col-lg-4 ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Customer Ledger:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Stay on top of your customer relationships with the
                      Customer Ledger. Easily track customer dues, payments, and
                      balances, ensuring transparency in your transactions."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Inventory Management:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Effortlessly manage your stock levels and keep your
                      inventory organized. Blubook's Inventory Management
                      feature helps you stay in control of your product
                      availability."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Item Ledger:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Get detailed insights into your items' stock, sales, and
                      transfer logs with the Item Ledger. It's your key to
                      maintaining accurate product records."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Stock Position:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Always know where you stand with Stock Position. Stay
                      informed about your inventory levels at a glance."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        POS Print:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Streamline your sales process and provide professional
                      receipts with POS Print. Instantly print transaction
                      receipts from your point-of-sale printer."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4 ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        MultiLingual:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Break language barriers with MultiLingual support.
                      Blubook offers language options to cater to a diverse
                      audience, ensuring clear communication."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Estimation/Quotations:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Provide potential customers with estimates and quotations
                      for their purchases, making your pricing transparent and
                      enticing."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        ZATCA Compatible Invoices:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Stay compliant with ZATCA regulations by generating
                      compatible invoices. Blubook ensures your invoicing meets
                      the required standards."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        VAT & TAX Statement:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Handle VAT and tax with ease. Blubook generates
                      comprehensive statements, simplifying the complex world of
                      taxes and compliance."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4 ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Item/Category wise Sale Report:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Analyze your sales data with precision. Our Item/Category
                      wise Sale Report breaks down sales information for
                      informed decision-making."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Offer Creation:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Attract and retain customers with enticing offers. Create
                      and manage special promotions and discounts to boost your
                      sales."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4  ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Supplier/Company Ledger:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Keep your supplier and company relationships transparent.
                      The Supplier/Company Ledger lets you track dues and
                      payments accurately."
                    </p>
                  </div>
                </div>
                <div className="  col-12 col-md-6 col-lg-4 ">
                  <div className="p-3 service-bg">
                    <div className="mb-2">
                      <span className="fs-4 fw-bold service-header">
                        Profit Loss Statement:
                      </span>
                    </div>

                    <p className="fs-5">
                      "Stay in control of your business's financial health. The
                      Profit Loss Statement provides insights into your profit
                      and loss, helping you make informed decisions."
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-gap">
              <div className="text-center">
                <span className="header-text-blubook  fw-bold">
                  Controls at a glance
                </span>
              </div>
              <Tabs className="mt-3 text-center mb-5">
                <TabList className="mb-4">
                  <Tab>Dashboard</Tab>
                  <Tab>Admin</Tab>
                  <Tab>Transaction</Tab>
                  <Tab>Transfer</Tab>
                  <Tab>Subscription</Tab>
                  <Tab>Customer</Tab>
                  <Tab>Company</Tab>
                  <Tab>Reports</Tab>
                </TabList>

                <TabPanel>
                  <div className="text-center">
                    <p>
                      Get an instant overview of key financial metrics like
                      Total Sales, Total Credit Sales, and Profits.
                    </p>
                    <img
                      className="col-12 col-md-6 "
                      src={blubook_dashboard}
                      alt=""
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="text-center">
                    <img
                      className="col-12 col-md-6"
                      src={blubook_admin}
                      alt=""
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="text-center">
                    <img
                      className="col-12 col-md-6"
                      src={blubook_transaction}
                      alt=""
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="text-center">
                    <img
                      className="col-12 col-md-6"
                      src={blubook_transfer}
                      alt=""
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="text-center">
                    <img
                      className="col-12 col-md-6"
                      src={blubook_subscription}
                      alt=""
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="text-center">
                    <img
                      className="col-12 col-md-6"
                      src={blubook_customer}
                      alt=""
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="text-center">
                    <img
                      className="col-12 col-md-6"
                      src={blubook_company}
                      alt=""
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="text-center">
                    <img
                      className="col-12 col-md-6"
                      src={blubook_report}
                      alt=""
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>

            <div className="section-gap ">
              <div className="text-center">
                <span className="header-text-blubook  fw-bold">Pricing</span>
                <h4>Straightforward and Budget-Friendly Pricing</h4>
                <p>
                  Blubook offers two straightforward and budget-friendly pricing
                  options: Regular and Premium. The Premium plan provides
                  unlimited access to all features and is ideal for businesses
                  seeking comprehensive account management solutions.
                </p>
              </div>
              <div className=" d-flex justify-content-center row mt-3">
                <div className=" text-center col-12 col-md-6">
                  <div className=" table-responsive">
                    <table className="table table-striped text-successtable-border border-light">
                      <thead className="border-light">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">
                            <div className="bg-blue-color p-1">
                              <strong className="fs-5 text-white">
                                Blubook Regular
                              </strong>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="bg-blue-color p-1">
                              <strong className="fs-5 text-white">
                                Blubook Premium
                              </strong>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Outlet</th>
                          <td>1</td>
                          <td>Unlimited</td>
                        </tr>
                        <tr>
                          <th scope="row">No. of Users</th>
                          <td>5</td>
                          <td>Unlimited</td>
                        </tr>
                        <tr>
                          <th scope="row">Invoices/Year</th>
                          <td>50,000</td>
                          <td>Unlimited</td>
                        </tr>
                        <tr>
                          <th scope="row">Expenses/Bills</th>
                          <td>50,000</td>
                          <td>Unlimited</td>
                        </tr>
                        <tr>
                          <th scope="row">Customized Workflow</th>
                          <td>N/A</td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <th scope="row">Transfer among Outlets</th>
                          <td>N/A</td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <th scope="row">Support Mode</th>
                          <td>Chat/Email</td>
                          <td>Chat/Email/Direct Call</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td className="">
                            <h4 className="text-blue-color">$49/month</h4>
                          </td>
                          <td className="">
                            <h4 className="text-blue-color">$79/month</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-gap">
              <div className="text-center">
                <span className="header-text-blubook  fw-bold">
                  Customer Review
                </span>
              </div>
              <section className="mt-3">
                <div className="row d-flex justify-content-center text-center">
                  <div className=" col-12 col-md-8">
                    <div
                      id="carouselBasicExample"
                      className="carousel slide carousel-dark"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <p className="fs-5 mx-4 mx-md-5">
                            "Blubook has completely revamped the way we handle
                            our finances and collaborate as a team. It's like
                            having a financial advisor right on our desktop.
                            Managing sales, expenses, and customer payments has
                            become a walk in the park. The user role settings
                            are a standout feature, making team collaboration
                            seamless."
                          </p>
                          <div className="mt-5 mb-4">
                            <img
                              src={linstarLogo}
                              className=" img-fluid shadow-1-strong"
                              alt="smaple image"
                              width="200"
                              height="200"
                            />
                          </div>
                        </div>

                        <div className="carousel-item">
                          <p className=" fs-5 mx-4 mx-md-5">
                            " Blubook is a true game-changer, simplifying
                            financial management and team collaboration. The
                            integration with other tools is like fitting puzzle
                            pieces together, and it offers a versatile toolbox
                            for managing every aspect of our business. A
                            must-have for any business on the rise!"
                          </p>
                          <div className="mt-5 mb-4">
                            <img
                              src={randomLogo}
                              className=" img-fluid shadow-1-strong"
                              alt="smaple image"
                              width="100"
                              height="100"
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselBasicExample"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselBasicExample"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="text-center join-us-section section-gap">
              <h1 className="fw-bold">Join Us in Achieving Success</h1>
              <p className="fs-5 mt-3">
                Join us in achieving success! If you have ideas, challenges, or
                opportunities that you'd like to explore, we're here to listen
                and collaborate. Our team is ready to discuss how we can help
                you leverage technology and innovation to reach your goals.
              </p>
              <h4 className=" mt-3 d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
                <Link
                  to="/contact-us"
                  className="text-decoration-none text-reset"
                >
                  Talk With Us
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBluBook;
