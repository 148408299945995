import React from "react";
import "./ContentManagement.m.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import CMcover from "../../images/1st-vector.png";
import CMcover2 from "../../images/2nd.png";
import CMcover3 from "../../images/Content-Management-Offer.png";
import liIcon from "../../images/li-icon.png";
import faqCover from "../../images/FAQ.png";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const ContentManagement = () => {
  return (
    <div className=" content-management-details">
      <ScrollToTop />
      <Helmet>
        <title>Content Management-Ideahub</title>
      </Helmet>
      <div className="main-section d-flex justify-content-center align-items-center ">
        <div className="container">
          <div className="row align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 ">
              <div>
                <h1 className="text-uppercase fw-bold ">Content Management</h1>
                <p className=" fs-4">Effortless Content Management Solutions</p>
                <h4 className=" d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
                  <Link to="/contact-us" className="text-decoration-none text-reset">Talk With Us</Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={CMcover} alt="" className="w-75 " />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row align-items-center g-5 mx-md-5 ">
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src={CMcover2}
              alt=""
              className="w-100 h-50 d-flex justify-content-center"
            />
          </div>
          <div className="col-lg-6 ">
            <h2 className="text-uppercase fw-bold ">
              Experience and Expertise:
            </h2>
            <p className=" fs-5">
              Empower your online business with our expert team. From content
              and e-commerce product management to Amazon/Noon account handling,
              graphics design, data entry, and social media management – we
              cover it all. With a proven track record across industries, we
              deliver quality that surpasses expectations. Leverage the latest
              tools for an attractive and engaging online presence. Eager for
              challenges, we're your partners in achieving online success.
              Contact us today. Your goals, our commitment.
            </p>
          </div>
        </div>

        <div className="row  align-items-center mx-md-5">
          <div className="col-md-6">
            <h2 className="text-uppercase fw-bold ">Services Offered:</h2>
            <ul className="mt-3">
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Ecommerce Product Management
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Graphics Design for Digital
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Social Media Account Management
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Data Entry 
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
              src={CMcover3}
              alt=""
              className="w-100 h-75 d-flex justify-content-center"
            />
          </div>
        </div>

        <div className="row g-5 mt-2 ">
          <div className="col-md-5 d-flex justify-content-center">
            <img src={faqCover} alt="" className="w-75" />
          </div>
          <div className="col-md-7 ">
            <h2 className=" fw-bold mb-3">Frequently Asked Questions:</h2>
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle={
                  <strong>
                    {" "}
                    How do I ensure my digital content remains organized and
                    up-to-date?
                  </strong>
                }
              >
                Our Content Management service focuses on organization and
                regular updates. We implement content calendars, version
                control, and content management systems to ensure your digital
                content remains well-organized and current.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle={
                  <strong>
                   How do I get started with your Content Management service?
                  </strong>
                }
              >
               To get started, simply reach out to us through our "Contact Us" page or provided contact details. We'll schedule a consultation to discuss your content management needs and create a customized plan to enhance your digital presence.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle={
                  <strong>
                  How does your data entry and migration expertise streamline processes?
                  </strong>
                }
              >
             Our data entry and migration expertise ensure smooth transitions when migrating content from one platform to another. This includes transferring data from legacy systems to modern platforms, maintaining data accuracy throughout the process.
              </MDBAccordionItem>
            </MDBAccordion>
          </div>
        </div>

        {/* <div className="row mt-5 align-items-center g-5 mx-md-5 ">
  <div className="col-lg-6 d-flex justify-content-center">
    <img src={contentImage4} alt="" className="w-75 " />
  </div>
  <div className="col-lg-6 ">
    <div>
      <h2 className="text-uppercase fw-bold ">Discover</h2>
      <p className=" fs-5">
        Content is the life of your IPTV/OTT platform. Once you have the
        content, you push it to our transcoder.
      </p>
      <p className=" fs-5">
        Content is the life of your IPTV/OTT platform. Once you have the
        content, you push it to our transcoder.
      </p>
    </div>
  </div>
</div>
<div className="section-2 mb-5 ">
  <div>
    <img src={contentImage5} className="w-100" />
  </div>
</div> */}
      </div>
    </div>
  );
};

export default ContentManagement;
