import React from 'react';
import './Banner.css';
import { Fragment } from 'react';

const Banner = () => {
    return (
        <Fragment>
            <div className="banner">
                <div className='banner-overlay'>
                    <div className='container '>
                        <div className='content'>
                            <h4 className='d-none d-sm-block'>ideahub</h4>
                            <h1>
                            Future built  <br></br>
                                <span>with idea</span>
                            </h1>
                            <h5 className="">Empowering Tomorrow through Innovative IT Solutions. Welcome to Ideahub BD - Where Future is Built with Ideas</h5>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Banner;