import React from "react";
import "./HospitalHub.m.css";
import { Link } from "react-router-dom";
import hospitalhub from "../../images/hospital-hub.svg";
import liIcon from "../../images/li-icon.png";
import agileSales from "../../images/agile-sales.svg";

import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const HospitalHub = () => {
  return (
    <div className="myblubook">
      <ScrollToTop></ScrollToTop>
      <Helmet>
        <title>HospitalHub - Ideahub</title>
      </Helmet>
      <div className="smart-accounts-details">
        <div className="Breadcrumb">
          <div>
            <h1>HospitalHub</h1>
            <p>
              <Link to="/#products">Products / </Link>HospitalHub
            </p>
          </div>
        </div>
        <div className="description-mybluebook">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img alt="ideahub" src={hospitalhub}></img>
              </div>
              <div className="col-md-7">
                <h1>HospitalHub - Unique needs of hospitals</h1>
                <p>
                  Welcome to HospitalHub, where healthcare management meets
                  innovation. Our comprehensive software is designed to address
                  the unique needs of hospitals, offering advanced features that
                  streamline operations and enhance patient care.
                </p>
                <h4>Features:</h4>
                <ul>
                  <li>
                    <img alt="ideahub" src={liIcon}></img>Patient Records Management
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img>Appointment Scheduling
                    Management.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img>Billing and Invoicing
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img>Inventory Management
                    loss.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalHub;
