import React from "react";
import "./OurExpertise.m.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ecommercCover from "../../images/E-Commerce.png";
import mediaIndustryCover from "../../images/Media-Industry.png";
import enterpriseCover from "../../images/Enterprise-Solution.png";
import professionalCover from "../../images/Professional-Service.png";
import CRMcover from "../../images/CRM-support.png";
import contentManagementCover from "../../images/ContentManagementCover.png";


const OurExpertise = () => {
  return (
    <div className="our-expertise-details">
      <ScrollToTop />
      <Helmet>
        <title>Our Expertise - Ideahub</title>
      </Helmet>
      <div className="Breadcrumb">
        <div>
          <h1>Our Expertise</h1>
        </div>
      </div>
      <div className="background-image ">
        <div className="container">
          <div className="row mt-2 align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 text-center">
              <div>
                <h4 className=" d-inline-block header-text px-3 py-2 rounded-2 fw-bold">
                  E-commerce
                </h4>
                <h5 className="text-uppercase fw-bold mt-2  ">
                  Elevate your online business
                </h5>
                <p className=" fs-5">
                  Elevate your online business with our tailored Ecommerce
                  solutions. From user-friendly interfaces to secure payment
                  gateways, we craft digital storefronts that provide seamless
                  shopping experiences. We empower businesses to thrive in the
                  competitive online market.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={ecommercCover} alt="" className="w-100" />
            </div>
          </div>
          <div className="row mt-1 align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={mediaIndustryCover} alt="" className="w-100" />
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <h4 className=" d-inline-block header-text px-3 py-2 rounded-2 fw-bold">
                  Media Industry
                </h4>
                <h5 className="text-uppercase fw-bold mt-2 ">
                  drive your brand's digital presence
                </h5>
                <p className=" fs-5">
                  In the fast-paced world of media, we drive your brand's
                  digital presence. Our strategies blend creativity and
                  technology to captivate audiences. From multimedia content
                  delivery to interactive platforms, we redefine how media
                  engages and informs.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-2 align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 text-center">
              <div>
                <h4 className=" d-inline-block header-text px-3 py-2 rounded-2 fw-bold">
                  Enterprise Solution
                </h4>
                <h5 className="text-uppercase fw-bold mt-2">
                  Empower your enterprise
                </h5>
                <p className=" fs-5">
                  Empower your enterprise with cutting-edge technologies. Our
                  enterprise solutions streamline operation, enhance
                  collaboration, and boost efficiency. We tailor solutions that
                  scale as your organization grows.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={enterpriseCover} alt="" className="w-100" />
            </div>
          </div>

          <div className="row mt-1 align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={professionalCover} alt="" className="w-100" />
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <h4 className=" d-inline-block header-text px-3 py-2 rounded-2 fw-bold">
                  Professional Service
                </h4>
                <h5 className="text-uppercase fw-bold mt-2 ">
                  optimize professional workflows
                </h5>
                <p className=" fs-5">
                  From IT Consultancy to Custom Development and DevOps, we
                  optimize professional workflows. Our solutions harness the
                  power of automation and digitization to simplify intricate
                  processes, enabling your team to channel their efforts into
                  what truly matters.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-2 align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 text-center">
              <div>
                <h4 className=" d-inline-block header-text px-3 py-2 rounded-2 fw-bold">
                  Content Management
                </h4>
                <h5 className="text-uppercase fw-bold mt-2">
                  Streamline your digital assets
                </h5>
                <p className=" fs-5">
                  Content is king, and we help you rule. Our content management
                  solutions empower you to organize, curate, and share content
                  effortlessly. Streamline your digital assets and communicate
                  your message effectively. Graphics Design, Content Upload,
                  E-commerce product management.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={contentManagementCover} alt="" className="w-100" />
            </div>
          </div>

          <div className="row mt-1 align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={CRMcover} alt="" className="w-100" />
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <h4 className=" d-inline-block header-text px-3 py-2 rounded-2 fw-bold">
                  CRM Support
                </h4>
                <h5 className="text-uppercase fw-bold mt-2 ">
                  improve customer communications
                </h5>
                <p className=" fs-5">
                  Customer relationships are at the heart of every successful
                  business. Our CRM solutions helps you manage, analyze, and
                  improve customer communications. We integrate data-driven
                  insights into your strategy for unparalleled customer
                  satisfaction.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* <div className="bg-white container px-5 py-5 mt-5 text-center rounded-5 logo-container ">
        <div className="row align-items-center g-5">
          <div className="col-md-4 d-flex justify-content-center">
            <img src={BaahLogo} alt="" className="w-50" />
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <img src={MadolLogo} alt="" className="w-50" />
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <img src={BaahLogo} alt="" className="w-50" />
          </div>
        </div>
        <button className="mt-4 header-text  px-3 py-2 rounded-5 fw-bold border-0 fs-5 ">
          case studies
        </button>
      </div> */}
    </div>
  );
};

export default OurExpertise;
