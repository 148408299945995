import React from "react";
import "./DataMigration.m.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import DMcover from "../../images/1st.png";
import DMcover2 from "../../images/Data-Mi-2nd.png";
import managedCover3 from "../../images/Data-Mi-Offer.png";
import liIcon from "../../images/li-icon.png";
import faqCover from "../../images/FAQ.png";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const DataMigration = () => {
  const migrations = [
    "WordPress to Custom CMS",
    "Enterprise Data Migration",
    "WordPress to Wagtail",
    "Squarespace to WordPress",
    "Wix to WordPress",
    "Joomla to WordPress",
    "Joomla to Other Platforms",
    "Wix to Other Platforms",
    "Custom Stack CMS to Other Platform",
  ];

  return (
    <div className=" Data-migration-details">
      <ScrollToTop />
      <Helmet>
        <title>Data Migration-Ideahub</title>
      </Helmet>
      <div className="main-section d-flex justify-content-center align-items-center ">
        <div className="container">
          <div className="row align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 ">
              <div>
                <h1 className="text-uppercase fw-bold ">Data Migration</h1>
                <p className=" fs-4">Seamless Data Migration Services </p>
                <h4 className=" d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
                  <Link to="/contact-us" className="text-decoration-none text-reset">Talk With Us</Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={DMcover} alt="" className="w-75 " />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row align-items-center g-5 mx-md-5 ">
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src={DMcover2}
              alt=""
              className="w-100  d-flex justify-content-center"
            />
          </div>
          <div className="col-lg-6 ">
            <h2 className="text-uppercase fw-bold ">
              Experience and Expertise:
            </h2>
            <p className=" fs-5">
              We have been providing data migration services for over 5 years,
              and we have worked with clients from different industries and
              sectors. We have migrated data for e-commerce websites, news
              portals, blogs, magazines, and more. We have a proven track record
              of delivering high-quality work and exceeding our clients’
              expectations. We have the skills and expertise to migrate data
              from any CMS platform to any other platform, using the latest
              technologies and methods. We can migrate data such as posts,
              pages, categories, tags, images, videos, comments, users,
              settings, and more.
            </p>
          </div>
        </div>

        <div className="row align-items-center mx-md-5">
          <div className="col-md-6">
            <h2 className="text-uppercase fw-bold ">Our Service</h2>
            <p className=" fs-5">
              We are a team of experts who specialize in data migration from
              various CMS platforms to your desired destination. We have
              successfully migrated data from WordPress, Quintype, Squarespace,
              Wix, Joomla, and other custom CMS platforms to different
              platforms, such as Wagtail, WordPress, and others. We have the
              experience and knowledge to handle any data migration challenge,
              no matter how complex or large-scale it is. We use the best
              practices and tools to ensure that your data is transferred
              securely, accurately, and efficiently.
            </p>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
              src={managedCover3}
              alt=""
              className="w-75 d-flex justify-content-center"
            />
          </div>
        </div>

        {/* <div className="row  align-items-center mx-md-5">
          <div className="col-md-6">
            <h2 className="text-uppercase fw-bold ">Services Offered:</h2>
            <ul className="mt-3">
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Ecommerce Product Management
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Graphics Design for Digital
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Social Media Account Management
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Data Entry and Migration Services
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
           
              alt=""
              className="w-100 h-75 d-flex justify-content-center"
            />
          </div>
        </div> */}

        <div className="mt-5">
          <div className="text-center mx-md-5">
            <h2 className="text-uppercase fw-bold ">Services Offered</h2>
            <p className="fs-5">
              We offer a range of data migration services to suit your needs and
              budget. Whether you need a one-time migration or a continuous sync
              between two platforms, we can help you with that. We can also help
              you with data backup, data cleansing, data validation, data
              transformation, and data optimization. We can migrate data from
              any source to any destination, such as:
            </p>
          </div>
          <div className="row g-3 mt-3 mb-5">
            {migrations.map((service, id) => (
              <div key={id} className="col-md-4">
                <div className="service-bg px-3 py-3">
                  <p className="fw-bold fs-5">{service}</p>
                </div>
              </div>
            ))}
          </div>
          <p className="fs-5">
          We can also migrate data from other platforms that are not listed here. Just contact us and let us know your requirements. We will provide you with a free quote and a detailed plan for your data migration project.
            </p>
        </div>

        <div className="row g-5 mt-2 ">
          <div className="col-md-5 d-flex justify-content-center">
            <img src={faqCover} alt="" className="w-75" />
          </div>
          <div className="col-md-7 ">
            <h2 className=" fw-bold mb-3">Frequently Asked Questions:</h2>
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle={
                  <strong>
                    {" "}
                    What types of data can be migrated using your services?
                  </strong>
                }
              >
                Our Data Migration Services can handle various types of data,
                including text, images, documents, databases, and more. We
                tailor the migration to your specific needs, ensuring all
                necessary data is transferred.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle={
                  <strong>
                    Are your services customizable to our unique migration
                    needs?
                  </strong>
                }
              >
                Absolutely. We understand that every data migration project is
                unique. Our consultants work closely with you to customize the
                migration process according to your specific requirements,
                ensuring a seamless transition.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle={
                  <strong>
                    How do I get started with your Data Migration Services?
                  </strong>
                }
              >
                To get started, simply reach out to us through our "Contact Us"
                page or the provided contact details. We'll initiate a
                consultation to understand your migration needs and create a
                tailored plan to ensure a seamless transition.
              </MDBAccordionItem>
            </MDBAccordion>
          </div>
        </div>

        {/* <div className="row mt-5 align-items-center g-5 mx-md-5 ">
  <div className="col-lg-6 d-flex justify-content-center">
    <img src={contentImage4} alt="" className="w-75 " />
  </div>
  <div className="col-lg-6 ">
    <div>
      <h2 className="text-uppercase fw-bold ">Discover</h2>
      <p className=" fs-5">
        Content is the life of your IPTV/OTT platform. Once you have the
        content, you push it to our transcoder.
      </p>
      <p className=" fs-5">
        Content is the life of your IPTV/OTT platform. Once you have the
        content, you push it to our transcoder.
      </p>
    </div>
  </div>
</div>
<div className="section-2 mb-5 ">
  <div>
    <img src={contentImage5} className="w-100" />
  </div>
</div> */}
      </div>
    </div>
  );
};

export default DataMigration;
