import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { AdSlot } from "react-dfp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Logo from "../../images/Logo.png";
import imoIcon from "../../images/imo-icon.png";
import "./Header.m.css";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Scroll to the section when the location changes
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      // Set isScrolled to true if the user has scrolled down, otherwise set it to false
      setIsScrolled(window.scrollY > 50);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isHidden, setIsHidden] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    // Define a mapping of hash fragments to page titles
    const titleMappings = {
      "#home": "",
      "#about": "About",
      "#products": "Products",
      "#service": "Services",
      // Add more hash fragments and titles as needed
    };

    // Get the current location hash
    const hash = window.location.hash;

    // Update the title based on the hash
    document.title = `${
      titleMappings[hash] || "Future built with idea"
    } - Ideahub`;
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsHidden(currentScrollY < prevScrollY);
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  return (
    <div id="home" className={isHidden ? "" : "hidden"}>
      <Navbar
        bg={isScrolled ? "myNav scrolled" : "myNav"} // Add the "scrolled" class when isScrolled is true
        variant="dark"
        sticky="top"
        expand="sm"
        collapseOnSelect
      >
        <div className="container">
          <span className="header-social-icon d-md-none">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/ideahubbdofficial"
            >
              {" "}
              <FontAwesomeIcon className="facebook" icon={faFacebook} size="xl" />{" "}
            </a>
            <a
              href="https://www.linkedin.com/company/ideahub-bd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FontAwesomeIcon className="linkedin" icon={faLinkedin} size="xl" />{" "}
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=8801967921081"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FontAwesomeIcon className="whatsapp" icon={faWhatsapp} size="xl" />{" "}
            </a>
            <a href="https://s.imoim.net/8w7noE" target="_blank">
              {" "}
              <img alt="ideahub" className="imoIcon" src={imoIcon}></img>
            </a>
          </span>
          <Link to="/">
            <Navbar.Brand>
              {/* img style had in app.css file */}
              <img className="img" src={Logo} alt="Logo" />
            </Navbar.Brand>
          </Link>

          <div className="menu-bar">
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav.Link as={Link} to="/#home">
                Home
              </Nav.Link>

              <Nav.Link as={Link} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/clients">
                Clients
              </Nav.Link>

              <NavDropdown
                title="Services"
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                <NavDropdown.Item href="/#service">
                  All Services
                </NavDropdown.Item>
                <NavDropdown.Item href="/cloud-management">
                  Cloud Management
                </NavDropdown.Item>
                <NavDropdown.Item href="/custom-development">
                  Custom Development
                </NavDropdown.Item>
                <NavDropdown.Item href="/managed-service">
                  Managed Services
                </NavDropdown.Item>
                <NavDropdown.Item href="/bi-dashboard">
                  BI Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item href="/content-management">
                  Content Management
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-migration">
                  Data Migration
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Products"
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                <NavDropdown.Item href="/#products">
                  All Products
                </NavDropdown.Item>
                <NavDropdown.Item href="/myBlubook">Blubook</NavDropdown.Item>
                <NavDropdown.Item href="/atlas-cms">Atlas CMS</NavDropdown.Item>
                <NavDropdown.Item href="/smart-account">
                  Smart Account
                </NavDropdown.Item>
                <NavDropdown.Item href="/hospitalhub">
                  HospitalHub
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link className="contact" href="/contact-us">
                Talk with Us
              </Nav.Link>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>

      <div className="desktop-ads">
        <AdSlot
          sizes={[
            [728, 90],
            [300, 250],
          ]}
          adUnit="top_ad_1"
        />
      </div>
      <div className="mobile-ads">
        <AdSlot
          sizes={[
            [320, 50],
            [300, 50],
          ]}
          adUnit="top_ad_1"
        />
      </div>
    </div>
  );
};

export default Header;
