import React from "react";

import { Link } from "react-router-dom";
import "./Services.m.css";
import mobileapp from "../../images/mobile-app.png";
import managedService from "../../images/technical-service.png";
import dataMigration from "../../images/file-sharing.png";
import cloudIcon from "../../images/cloud icon.png";
import development from "../../images/development.png";
import ui from "../../images/ui.png";
import reachability from "../../images/reach.png";
import bidashboard from "../../images/bi-dashboard.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <div className="our-services" id="service">
      <div className="container">
        <div className="header">
          <h5>Our Services</h5>
          <h2>We provided world class service</h2>
        </div>
        <div className="services">
          {/* <img src={servicepic}></img> */}
          <div className="row">
            <div className="col-md-4 service-box">
              <div className="service-items">
                <div className="service-names">
                  <img className="" src={cloudIcon} alt="images" />
                  <h4 className="service-title">Cloud Management</h4>
                </div>
                <div className="service-details">
                  <p>
                    With over 5 years of experience working with the top 3
                    public cloud platforms - AWS, Azure, and DigitalOcean - we
                    offer you the ultimate in performance, bulletproof security,
                    and top-rated support.
                  </p>
                </div>
                <div className="see-more">
                  <Link to="/cloud-management">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 service-box">
              <div className="service-items">
                <div className="service-names">
                  <img className="imag" src={development} alt="images" />
                  <h4 className="service-title">Custom Development</h4>
                </div>
                <div className="service-details">
                  <p>
                    With a track record of 15+ years in .NET and C#, as well as
                    3+ years in the MERN stack and headless technology, we craft
                    software solutions that push boundaries. Our expertise
                    extends to cross-platform mobile app development and
                    crafting corporate websites that leave lasting impressions.
                  </p>
                </div>
                <div className="see-more">
                  <Link to="/custom-development">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 service-box">
              <div className="service-items">
                <div className="service-names">
                  <img className="imag" src={managedService} alt="images" />
                  <h4 className="service-title">Managed Services</h4>
                </div>
                <div className="service-details">
                  <p>
                    Experience hassle-free IT with our Managed Services. From
                    Email and E-Commerce Operations to WordPress and DevOps,
                    we're your partner in maintaining digital excellence. Our
                    expert team takes care of intricate details, ensuring your
                    systems are always optimized for peak performance.
                  </p>
                </div>
                <div className="see-more">
                  <Link to="/managed-service">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 service-box">
              <div className="service-items">
                <div className="service-names">
                  <img className="imag" src={bidashboard} alt="images" />
                  <h4 className="service-title">BI Dashboard</h4>
                </div>
                <div className="service-details">
                  <p>
                    Unlock insights and drive decisions with our BI Dashboard
                    service. With expertise in creating custom dashboards using
                    Power BI and QlikView/QlikSense, we help you transform raw
                    data into actionable insights. Visualize trends, analyze
                    performance, and make informed choices that propel your
                    business forward.
                  </p>
                </div>
                <div className="see-more">
                  <Link to="/bi-dashboard">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 service-box">
              <div className="service-items">
                <div className="service-names">
                  <img className="imag" src={reachability} alt="images" />
                  <h4 className="service-title">Content Management</h4>
                </div>
                <div className="service-details">
                  <p>
                    Effortlessly manage your content with our Content Management
                    service. From Ecommerce Product Management to Graphics
                    Design and Social Media Account Management, we ensure your
                    digital presence stands out. Our data entry and migration
                    expertise streamline processes, keeping your data accurate
                    and organized.
                  </p>
                </div>
                <div className="see-more">
                  <Link to="/content-management">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 service-box">
              <div className="service-items">
                <div className="service-names">
                  <img className="imag" src={dataMigration} alt="images" />
                  <h4 className="service-title">Data Migration</h4>
                </div>
                <div className="service-details">
                  <p>
                    Transition with ease using our Data Migration Services.
                    Whether you're migrating between CMS platforms or
                    customizing your migration needs, our consultants ensure a
                    seamless transfer of data. Let us handle the complexities,
                    leaving you with a fully operational solution.
                  </p>
                </div>
                <div className="see-more">
                  <Link to="/data-migration">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
