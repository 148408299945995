import React from "react";
import "./ManagedService.m.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import managedCover from "../../images/Manage-Service-1st-Vector.png";
import managedCover2 from "../../images/Manage-Service-Expert-2nd-Vec.png";
import managedCover3 from "../../images/offer-2.png";
import liIcon from "../../images/li-icon.png";
import faqCover from "../../images/FAQ.png";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const ManagedService = () => {
  return (
    <div className=" managed-service-details">
      <ScrollToTop />
      <Helmet>
        <title>Managed Services-Ideahub</title>
      </Helmet>
      <div className="main-section d-flex justify-content-center align-items-center ">
        <div className="container">
          <div className="row align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 ">
              <div>
                <h1 className="text-uppercase fw-bold ">Managed Services</h1>
                <p className=" fs-4">
                  Experience Excellence in Managed Services with Ideahub BD
                </p>
                <h4 className=" d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
                  <Link
                    to="/contact-us"
                    className="text-decoration-none text-reset"
                  >
                    Talk With Us
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={managedCover} alt="" className="w-100 " />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center g-5 mx-md-5 ">
          <div className="col-lg-5 d-flex justify-content-center">
            <img
              src={managedCover2}
              alt=""
              className="w-100 d-flex justify-content-center"
            />
          </div>
          <div className="col-lg-7 ">
            <h2 className="text-uppercase fw-bold ">
              Experienced and Expert:
            </h2>
            <p className=" fs-5">
              Our experts who can handle email, e-commerce, WordPress, and
              DevOps solutions for your business. We have more than 5 years of
              experience and certification in these fields.
            </p>
          </div>
        </div>

        <div className="row align-items-center mx-md-5">
          <div className="col-md-7">
            <h2 className="text-uppercase fw-bold ">Services Offered:</h2>
            <ul className="mt-3">
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Email Management and Optimization
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    E-Commerce Operations Management
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    WordPress Support and Maintenance
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    DevOps Solutions and Support
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-5 d-flex justify-content-center">
            <img
              src={managedCover3}
              alt=""
              className="w-100 d-flex justify-content-center"
            />
          </div>
        </div>

        <div className="row g-5 mt-3">
          <div className="col-md-5 d-flex justify-content-center">
            <img src={faqCover} alt="" className="w-75" />
          </div>
          <div className="col-md-7 ">
            <h2 className=" fw-bold mb-3">Frequently Asked Questions:</h2>
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle={
                  <strong>
                    {" "}
                    What types of operations are covered by your Managed
                    Services?
                  </strong>
                }
              >
                Our Managed Services cover a wide range of operations, including
                Email management, E-Commerce operations, WordPress maintenance,
                and DevOps support. We handle the intricate technical aspects,
                so you can concentrate on achieving your business goals.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle={
                  <strong>How can Managed Services benefit my business?</strong>
                }
              >
                Managed Services provide numerous benefits, such as enhanced
                operational efficiency, reduced downtime, improved security, and
                access to expert IT professionals. By outsourcing these
                responsibilities, you can allocate your resources more
                effectively and ensure consistent digital excellence.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle={
                  <strong>
                    Is the scope of Managed Services customizable to our needs?
                  </strong>
                }
              >
                Yes, our Managed Services are highly customizable to match your
                unique requirements. We work closely with you to tailor the
                services to your business needs, ensuring you receive the
                support you need without paying for unnecessary features.
              </MDBAccordionItem>
            </MDBAccordion>
          </div>
        </div>

        {/* <div className="row mt-5 align-items-center g-5 mx-md-5 ">
      <div className="col-lg-6 d-flex justify-content-center">
        <img src={contentImage4} alt="" className="w-75 " />
      </div>
      <div className="col-lg-6 ">
        <div>
          <h2 className="text-uppercase fw-bold ">Discover</h2>
          <p className=" fs-5">
            Content is the life of your IPTV/OTT platform. Once you have the
            content, you push it to our transcoder.
          </p>
          <p className=" fs-5">
            Content is the life of your IPTV/OTT platform. Once you have the
            content, you push it to our transcoder.
          </p>
        </div>
      </div>
    </div>
    <div className="section-2 mb-5 ">
      <div>
        <img src={contentImage5} className="w-100" />
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default ManagedService;
