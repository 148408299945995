import React from "react";
import "./Clients.m.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import baahLogo from "../../images/clientsLogo/Baah-logo.png";
import bhiveLogo from "../../images/clientsLogo/bHive-logo.png";
import linstarLogo from "../../images/clientsLogo/linstar-logo.png";
import soradoiLogo from "../../images/clientsLogo/sorardoi-logo.png";
import WTBLogo from "../../images/clientsLogo/WTB-logo.png";
import f3logo from "../../images/clientsLogo/Logo F-3.jpg";
import randomLogo from "../../images/clientsLogo/178538474_450859356827553_4952970996603323508_n (1).jpg";

const Clients = () => {
  return (
    <div className="container client-section">
      <ScrollToTop />
      <Helmet>
        <title>Our Clients-Ideahub</title>
      </Helmet>
      <div className="header">
        <h5>Our Valuable Clients</h5>
        <h2>Experience the difference with our services</h2>
      </div>
      <div className="slider-body mt-5">
        <div class="slider">
          <div class="slide-track mt-4">
            <div class="slide">
              <img src={baahLogo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={bhiveLogo} className="carousel-image" alt="" />
            </div>
            <div class="slide">
              <img src={linstarLogo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={soradoiLogo} className="carousel-image" alt="" />
            </div>
            <div class="slide">
              <img src={WTBLogo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={f3logo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={randomLogo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={baahLogo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={bhiveLogo} className="carousel-image" alt="" />
            </div>
            <div class="slide">
              <img src={linstarLogo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={soradoiLogo} className="carousel-image" alt="" />
            </div>
            <div class="slide">
              <img src={WTBLogo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={f3logo} alt="" className="carousel-image" />
            </div>
            <div class="slide">
              <img src={randomLogo} alt="" className="carousel-image" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row row-gap-5 mt-5 ">
        <div className=" col-6 col-md-3 d-flex justify-content-center p-4">
          <img
            className="w-50 object-fit-contain"
            src={baahLogo}
            alt=""
            srcset=""
          />
        </div>
        <div className=" col-6 col-md-3 d-flex justify-content-center p-4">
          <img
            className="w-50 object-fit-contain"
            src={bhiveLogo}
            alt=""
            srcset=""
          />
        </div>
        <div className=" col-6 col-md-3 d-flex justify-content-center">
          <img
            className="w-50 object-fit-contain"
            src={linstarLogo}
            alt=""
            srcset=""
          />
        </div>
        <div className=" col-6 col-md-3 d-flex justify-content-center">
          <img
            className="w-50 object-fit-contain "
            src={soradoiLogo}
            alt=""
            srcset=""
          />
        </div>
        <div className=" col-6 col-md-3 d-flex justify-content-center">
          <img
            className="w-50 object-fit-contain "
            src={WTBLogo}
            alt=""
            srcset=""
          />
        </div>
      </div> */}
    </div>
  );
};

export default Clients;
