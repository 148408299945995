import React from "react";
import { Link } from "react-router-dom";

import accountpic from "../../images/smart-account.svg";
import accountfeature from "../../images/accounts.png";
import liIcon from "../../images/li-icon.png";

import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import "./SmartAccount.m.css";

const SmartAccount = () => {
  return (
    <div className="smart-account">
      <ScrollToTop></ScrollToTop>
      <Helmet>
        <title>Smart Account - Ideahub</title>
      </Helmet>
      <div className="smart-accounts-details">
        <div className="Breadcrumb">
          <div>
            <h1>Smart Account</h1>
            <p>
              <Link to="/#products">Products / </Link>Smart Account
            </p>
          </div>
        </div>
        <div className="description1">
          <div className="container">
            <h1>Smart Accounting: Streamlining Financial Management</h1>
            <div className="row align-items-center">
              <div className="col-md-4">
                <img alt="ideahub" src={accountpic}></img>
              </div>
              <div className="col-md-8">
                <h2>The ultimate Smart Accounting app your business needs. </h2>
                <p>
                  Smart Accounts takes the hassle out of inventory and account
                  management. Our intuitive software offers seamless tracking of
                  inventory and financial transactions, helping you maintain
                  control and accuracy.
                </p>
              </div>
            </div>
            <div className="row align-items-center mt-5 sales-features">
              <div className="col-md-7">
                <h4>Features:</h4>
                <ul>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Inventory Management.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Account Tracking and Management.
                  </li>
                  <li>
                    <img alt="ideahub" src={liIcon}></img> Financial Transaction Monitoring.
                  </li>
                </ul>
              </div>
              <div className="col-md-5">
                <img alt="ideahub" src={accountfeature}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartAccount;
