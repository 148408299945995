import React, { useState, useEffect } from "react";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import "./CloudManagement.m.css";
import { Helmet } from "react-helmet";
import liIcon from "../../images/li-icon.png";
import cloudCover from "../../images/Final-clound-Management.png";
import cloudCover2 from "../../images/Final-Expert-2.png";
import awsIcon from "../../images/icons8-amazon-web-services-240.svg";
import azureIcon from "../../images/icons8-azure-240.svg";
import digitalOceanIcon from "../../images/icons8-digitalocean-250.svg";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import faqCover from "../../images/FAQ.png";
import { Link } from "react-router-dom";

const CloudManagement = () => {
  const [selectedProvider, setSelectedProvider] = useState("aws");
  const [selectedServiceList, setSelectedServiceList] = useState([]);

  const awsService = [
    "Network",
    "RDS",
    "S3",
    "S3 Object",
    "Secrets Manager",
    "SNS",
    "Jenkins",
    "Application Load Balancer",
    "Cloudwatch Log Groups",
    "Cloudwatch Alarms",
    "Compute",
    "DynamoDB",
    "IAM Role",
    "IAM Policy",
    "IAM User",
    "IAM Role Policy Attachment",
    "EBS volume",
    "Security Group",
    "VPC",
    "Subnet",
    "Route 53",
    "EC2 Instance",
    "S3",
    "EKS",
    "ECS",
    "AWS Fargate",
    "Secrets Manager",
    "SNS",
    "RDS",
    "Lambda",
    "API Gateway",
    "Auto Scaling",
    "Cloudfront",
  ];
  const azureServices = [
    "Resource Group",
    "Network security group",
    "Key Vault",
    "Storage Account",
    "Virtual Machine",
    "CloudWatch Alarms",
    "App Service",
    "Azure SQL Database",
    "Azure Functions",
    "Azure Container Instances",
    "Application Gateway",
    "Load Balancer",
    "Azure Firewall",
    "Azure Front Door",
    "Traffic Manager",
    "VPN Gateway",
    "Azure CDN",
    "Storage Account",
    "Notification Hub",
    "Azure Container Apps",
    "Azure Cache For Redis",
    "Azure Resource Manager REST API",
    "AKS",
    "Managed Disk",
    "Azure Active Directory",
    "Azure DevOps",
  ];
  const digitalOceanServices = [
    "Apps",
    "Droplets",
    "Functions",
    "Kubernetes",
    "Volumes",
    "Databases",
    "Images",
    "Networking",
    "Monitoring",
  ];

  useEffect(() => {
    handleProviderClick("aws"); // Call the function with 'aws' when component mounts
  }, []);

  const handleProviderClick = (provider) => {
    setSelectedProvider(provider);

    if (provider === "aws") {
      setSelectedServiceList(awsService);
    } else if (provider === "azure") {
      setSelectedServiceList(azureServices);
    } else if (provider === "digitalOcean") {
      setSelectedServiceList(digitalOceanServices);
    }
  };

  return (
    <div className=" cloud-management-details">
      <ScrollToTop />
      <Helmet>
        <title>Cloud Management - Ideahub</title>
      </Helmet>
      <div className="main-section d-flex justify-content-center align-items-center ">
        <div className="container">
          <div className="row align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 ">
              <div>
                <h1 className="text-uppercase fw-bold ">Cloud Management</h1>
                <p className=" fs-4">
                  Mastering Cloud Excellence: Your Performance, Security, and
                  Support Partner
                </p>
                <h4 className=" d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
                  <Link to="/contact-us" className="text-decoration-none text-reset">Talk With Us</Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={cloudCover} alt="" className="w-75 " />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center g-5 mx-md-5 ">
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src={cloudCover2}
              alt=""
              className="w-100 d-flex justify-content-center"
            />
          </div>
          <div className="col-lg-6 ">
            <h2 className="text-uppercase fw-bold ">
              Experience and Expertise:
            </h2>
            <p className=" fs-5">
              With over 5 years of experience working with the top 3 public
              cloud platforms - AWS, Azure, and DigitalOcean - Ideahub BD brings
              you unparalleled expertise in cloud management. We ensure ultimate
              performance, bulletproof security, and top-rated support.
            </p>
            {/* <ul className="mt-3">
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    A mobile app can provide a more convenient way for customers
                    to interact
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    A mobile app can provide a more convenient way for customers
                    to interact
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    A mobile app can provide a more convenient way for customers
                    to interact
                  </p>
                </div>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="mt-5 ">
          <div className="text-center ">
            <h2 className="text-uppercase fw-bold ">Services Offered</h2>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center image-container mt-5 flex-sm-row mb-5">
            <div
              className="icon-container"
              onClick={() => handleProviderClick("aws")}
            >
              <img src={awsIcon} alt="" className="icon" />
              <div
                className={`icon-bar ${
                  selectedProvider === "aws" ? "active" : ""
                }`}
              ></div>
            </div>
            <div
              className="icon-container"
              onClick={() => handleProviderClick("azure")}
            >
              <img src={azureIcon} alt="" className="icon" />
              <div
                className={`icon-bar ${
                  selectedProvider === "azure" ? "active" : ""
                }`}
              ></div>
            </div>
            <div
              className="icon-container"
              onClick={() => handleProviderClick("digitalOcean")}
            >
              <img src={digitalOceanIcon} alt="" className="icon" />
              <div
                className={`icon-bar ${
                  selectedProvider === "digitalOcean" ? "active" : ""
                }`}
              ></div>
            </div>
          </div>

          <div className={`service-list ${selectedProvider ? "active" : ""}`}>
            <div className="row g-3 mb-5">
              {selectedServiceList.map((service, id) => (
                <div key={id} className="col-md-3">
                  <div className="service-bg px-3 py-2">
                    <p className="fw-bold">{service}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row g-5 faq-section">
          <div className="col-md-5 d-flex justify-content-center">
            <img src={faqCover} alt="" className="w-75 " />
          </div>
          <div className="col-md-7 ">
            <h2 className=" fw-bold mb-3">Frequently Asked Questions:</h2>
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle={<strong>What is Cloud Management?</strong>}
              >
                <strong>Cloud Management</strong> refers to the practice of
                overseeing and optimizing cloud resources and services provided
                by platforms like AWS, Azure, and DigitalOcean. It involves
                tasks such as provisioning, monitoring, security management,
                cost optimization, and ensuring the efficient operation of
                cloud-based applications.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle={
                  <strong>
                    How do I get started with your Cloud Management service?
                  </strong>
                }
              >
                Getting started is easy! Simply reach out to us through our
                contact page or call our support line. Our team will schedule a
                consultation to understand your needs and tailor a solution that
                aligns with your goals.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle={
                  <strong>
                    Can you help migrate our existing infrastructure to the
                    cloud?
                  </strong>
                }
              >
                Yes, we offer migration services to help you smoothly transition
                your existing infrastructure to the cloud. Our experts will plan
                and execute the migration, ensuring minimal disruptions to your
                operations.
              </MDBAccordionItem>
            </MDBAccordion>
          </div>
        </div>

        {/* <div className="row mt-5 align-items-center g-5 mx-md-5 ">
          <div className="col-lg-6 d-flex justify-content-center">
            <img src={contentImage4} alt="" className="w-75 " />
          </div>
          <div className="col-lg-6 ">
            <div>
              <h2 className="text-uppercase fw-bold ">Discover</h2>
              <p className=" fs-5">
                Content is the life of your IPTV/OTT platform. Once you have the
                content, you push it to our transcoder.
              </p>
              <p className=" fs-5">
                Content is the life of your IPTV/OTT platform. Once you have the
                content, you push it to our transcoder.
              </p>
            </div>
          </div>
        </div>
        <div className="section-2 mb-5 ">
          <div>
            <img src={contentImage5} className="w-100" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CloudManagement;
