import React from "react";
import "./Topbar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import imoLogo from "../../images/imo.png";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

function Topbar() {
  return (
    <div className="topbar d-none d-md-block">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 desktop">
            <ul>
              <li>
                <FontAwesomeIcon icon={faEnvelope} /> info@ideahubbd.com
              </li>
            </ul>
          </div>
          <div className="col-sm-6 top-right">
            <ul>
              <li>
                <FontAwesomeIcon icon={faMobileScreen} /> (+880) 196 7921081
              </li>
              <li>
                <span className="desktop">follow us on:</span>
                <span className="topbar-social-icon">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/ideahubbdofficial"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faFacebook}size="xl"/>{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/ideahub-bd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faLinkedin} size="xl" />{" "}
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=8801967921081"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faWhatsapp} size="xl"/>{" "}
                  </a>
                  <a
                    href="https://s.imoim.net/8w7noE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img alt="ideahub" className="imologo" src={imoLogo}></img>
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
