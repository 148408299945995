import React from 'react'
import './AboutUs.css'
import aboutpic from '../../images/about2.png'
import { Link } from 'react-router-dom'

function AboutUs() {
  return (
    <section className='about' id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <img alt='about' src={aboutpic}></img>
          </div>
          <div className='col-md-6 about-content'>
            <h3>our expertise</h3>
            <h1>Creating a better solutions industry standard</h1>
            <p className='fs-6'>We Provide Mobile Apps, Custom Development, Web Development, CRM Support, Reachability, BI Dashboard, SEO, solution for Ecommerce, Content Management & Media industry</p>
            <div className='wehave'>
              <div className='countries'>
                <h4>We have reached</h4>
                <h1>5</h1>
                <h4>Countries</h4>
              </div>
              <div className='expertise-button'>
              <Link to="/our-expertise"><button className='btn btn-primary'>learn more</button></Link>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs