import pic2 from '../Picture/ErrorImages/404-page-ideahubbd.png';

import "./ErrorPage.m.css";
import { Helmet } from 'react-helmet';

const errorPage = () => {
    return (
        <div>
            <Helmet>
                <title>404, Page not found</title>
            </Helmet>
            <div className='error-message text-center'>
                <div>
                    <img className='err-img' src={pic2} alt="Error" />
                    <h2>Page Not Found</h2>
                    <h4>
                        <button className='go-home-btn'><a href="/home">Go Home</a></button>
                    </h4>
                </div>
            </div>
        </div>

    );
};

export default errorPage;