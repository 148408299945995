import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Helmet from "react-helmet";
import { Route, Routes } from "react-router-dom";
import SmartAccount from "./Components/SmartAccount/SmartAccount";
import Home from "./Home/Home";
import AtlasCms from "./Components/AtlasCms/AtlasCms";
import ErrorPage from "./Components/404Page/ErrorPage";

import Topbar from "./Components/Topbar/Topbar";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import BiDashboard from "./Components/BiDashboard/BiDashboard";

import CustomDev from "./Components/CustomDev/CustomDev";
import CloudManagement from "./Components/CloudManagement/CloudManagement";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faClock, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import OurExpertise from "./Components/OurExpertise/OurExpertise";
import ManagedService from "./Components/MangedService/ManagedService";
import ContentManagement from "./Components/ContentManagement/ContentManagement";
import DataMigration from "./Components/DataMigration/DataMigration";
import MyBluBook from "./Components/MyBluBook/MyBluBook";
import HospitalHub from "./Components/HospitalHub/HospitalHub";
import ContactUs from "./Components/ContactUS/ContactUs";
import Clients from "./Components/Clients/Clients";
import AboutUsPage from "./Components/AboutUsPage/AboutUsPage";

library.add(faClock, faEnvelope, faFacebook, faInstagram);

function App() {
  return (
    <div className="home-overlay">
      <Helmet>
        <meta
          name="description"
          content="Create amazing website, UI/UX, Android App"
        />
        <meta name="keywords" content="Websites, UI/UX, Android App" />
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        ></meta>
        <link rel="canonical" href="https://ideahubbd.com/" />
      </Helmet>
      <Topbar></Topbar>
      <Header></Header>
      <Routes>
        <Route exact path="/" element={<Home></Home>} />
        <Route path="/home" element={<Home></Home>} />
        <Route path="/about" element={<AboutUsPage></AboutUsPage>} />
        <Route path="/smart-account" element={<SmartAccount></SmartAccount>} />

        <Route path="/atlas-cms" element={<AtlasCms></AtlasCms>} />
        <Route path="/myBlubook" element={<MyBluBook></MyBluBook>} />
        <Route path="/hospitalhub" element={<HospitalHub></HospitalHub>} />
        <Route path="*" element={<ErrorPage></ErrorPage>} />
        <Route path="/managed-service" element={<ManagedService />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/bi-dashboard" element={<BiDashboard />} />
        <Route path="/content-management" element={<ContentManagement />} />
        <Route path="/cloud-management" element={<CloudManagement />} />
        <Route path="/our-expertise" element={<OurExpertise />} />
        <Route path="/data-migration" element={<DataMigration />} />
        <Route path="/clients" element={<Clients />} />

        <Route path="/custom-development" element={<CustomDev />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
