import React from "react";
import Banner from "../Components/Banner/Banner";
import AboutUs from "../Components/AboutUs/AboutUs";
import Products from "../Components/Products/Products";
import Services from "../Components/Services/Services";

const Home = () => {
  
  return (
    <div>
      <Banner></Banner>
      <AboutUs></AboutUs>
      <Services></Services>
      <Products></Products>
    </div>
  );
};

export default Home;
