import React, { useState } from "react";
import "./ContactUs.m.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import bdFlag from "../../images/bangladesh.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import imoIcon from "../../images/imo-icon.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    email: "",
    subject: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      // Step 1: Send a POST request to obtain a JWT token
      const tokenResponse = await axios.post(
        "https://capi.ideahubbd.com/api/token/",
        {
          username: "hub",
          password: "*hubc0ntact#",
        }
      );

      const accessToken = tokenResponse.data.access;
      console.log("Access token:", accessToken);

      // Step 2: Send a POST request to send an email with JWT token in the Authorization header
      await axios.post(
        "https://capi.ideahubbd.com/api/send-email/",
        {
          toEmail: "info@ideahubbd.com",
          subject: values.subject,
          message: `This message is sent from ${values.email}\n\n${values.message}`,
          replyToEmail: values.email,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      resetForm();
      setLoading(false);
      toast.info("Email sent successfully!");
    } catch (error) {
      setLoading(false);
      toast.error("Error sending email. Please try again later.");
    }
  };

  return (
    <div className="">
      <Helmet>
        <title>Contact Us - Ideahub</title>
      </Helmet>
      <div className="container mt-5 mb-5 ">
        <div className="contact-details">
          <div className="row g-5">
            <div className=" col-12 col-lg-6 mr-auto">
              <h2 className="">Contact Us</h2>
              <div className="col-12 mt-4">
                <div className="address">
                  {/* <div className="add-1 mb-4  col-12">
                    <div className="d-flex flex-row align-items-center text-center">
                      <img className="flag" src={bdFlag} alt="" />
                      <h5 className="fw-bold mx-3 mt-2">Dhaka Office</h5>
                    </div>
                    <p>House#15, Road#7, Gulshan-1 Circle, Dhaka 1212</p>
                  </div> */}
                  <div className="add-2 mb-4 col-12">
                    <div className="d-flex flex-row align-items-center text-center">
                      <img className="flag" src={bdFlag} alt="" />
                      <h5 className="fw-bold mx-3 mt-2">Dhaka Office</h5>
                    </div>
                    <p className="fs-5">
                    Ground Floor, House-15, Road-8, Gulshan-1, Dhaka-1212
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center mt-4 mt-md-0 ">
                  <div className="phone-icon">
                    <FontAwesomeIcon size="lg" icon={faMobileScreen} />
                  </div>{" "}
                  <p className="fs-4">(+880) 196 7921081</p>
                </div>
                <div>
                  <p className="fs-4 fw-bold">info@ideahubbd.com</p>
                </div>
                <h4 className="fw-bold text-uppercase mt-3">
                  <span className="">follow us on:</span>
                </h4>

                <div className="contact-social-icon mt-3">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/ideahubbdofficial"
                  >
                    {" "}
                    <FontAwesomeIcon
                      className="facebook me-3 "
                      icon={faFacebook}
                      size="2x"
                    />{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/ideahub-bd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FontAwesomeIcon
                      className="linkedin me-3"
                      icon={faLinkedin}
                      size="2x"
                    />{" "}
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=8801967921081"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FontAwesomeIcon
                      className="whatsapp me-3"
                      icon={faWhatsapp}
                      size="2x"
                    />{" "}
                  </a>
                  <a
                    href="https://s.imoim.net/8w7noE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img alt="ideahub" className="imoIcon" src={imoIcon}></img>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 form-bg">
              <h3 className="fw-bold mt-3 ">Fill up the Form</h3>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form id="contactForm" name="contactForm" className="mb-5">
                    <div className="row mt-3">
                      <div className="col-md-12 form-group">
                        <label htmlFor="email" className="col-form-label">
                          Email
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12 form-group">
                        <label htmlFor="subject" className="col-form-label">
                          Subject
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="subject"
                          id="subject"
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12 form-group">
                        <label htmlFor="message" className="col-form-label">
                          Message
                        </label>
                        <Field
                          as="textarea"
                          className="form-control"
                          name="message"
                          id="message"
                          cols="30"
                          rows="7"
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        {loading ? (
                          <TailSpin
                            height="60"
                            width="60"
                            color="#5f70ff"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary rounded-0 py-2 px-4"
                          >
                            Send Message
                          </button>
                        )}

                        <span className="submitting"></span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="colored"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
