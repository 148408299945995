import React from "react";
import "./AboutUsPage.m.css";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
  return (
    <div className="about-us-section">
      <ScrollToTop></ScrollToTop>
      <Helmet>
        <title>About Us- Ideahub</title>
      </Helmet>
      <div className="about-us-bg">
        <span className="fw-bold ">About Us</span>
      </div>
      <div className="container">
        <div className="row d-flex about-us-page-gap align-items-center mx-lg-5">
          <div className="col-12 col-md-3 d-flex flex-column text-uppercase fw-bold lh-sm about-us-title align-items-start  align-items-md-end">
            <span className="head-title">Who</span>
            <span className="sub-title">we are</span>
          </div>
          <div className="col-12 col-md-9 px-o px-md-5">
            <p className="fs-5">
              Ideahub BD came into existence in 2021, founded by three
              enthusiastic young individuals who firmly believe that the future
              is built with ideas. We are more than just a technology company,
              we are an Idea Hub, dedicated to transforming innovative concepts
              into practical solutions that empower businesses and organizations
              to thrive in the digital age.
            </p>
          </div>
        </div>
        <div className="about-us-page-gap p-5 mission-section">
          <div className="header">
            <span className="header-title">Mission</span>
          </div>
          <p className="fs-5">
            Our mission is to harness the power of creativity, innovation, and
            technology to drive positive change and help our clients achieve
            their goals. We are committed to providing world-class IT solutions
            and services that elevate online businesses, drive brand digital
            presence, empower enterprises, optimize professional workflows,
            streamline content management, and facilitate seamless data
            migration.
          </p>
        </div>

        <div className="about-us-page-gap p-5 vision-section">
          <div className="header">
            <span className="header-title">Vision</span>
          </div>

          <p className="fs-5">
            Our vision is to be a leading force in the world of technology,
            setting industry standards and redefining the way businesses operate
            in the digital landscape. We aspire to be the go-to partner for
            businesses seeking excellence in cloud management, custom
            development, managed services, BI dashboard solutions, content
            management, and data migration. We envision a future where
            businesses can leverage the latest tools and innovations to achieve
            unparalleled success.
          </p>
          <p className="fs-5">
            At Ideahub BD, we believe that every great endeavor begins with a
            simple idea. We are here to turn those ideas into reality, to
            innovate, and to create a brighter digital future. Join us on this
            journey, and together, we will build a tomorrow where possibilities
            are limitless.
          </p>
        </div>

        <div className="text-center join-us-section about-us-page-gap">
          <h1 className="fw-bold">Join Us in Achieving Success</h1>
          <p className="fs-5 mt-3">
            Join us in achieving success! If you have ideas, challenges, or
            opportunities that you'd like to explore, we're here to listen and
            collaborate. Our team is ready to discuss how we can help you
            leverage technology and innovation to reach your goals.
          </p>
          <h4 className=" mt-3 d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
            <Link to="/contact-us" className="text-decoration-none text-reset">
              Talk With Us
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
