import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./AtlasCms.m.css";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import cmspic from "../../images/atlas-cms.svg";
import liIcon from "../../images/li-icon.png";
import CMS from "../../images/Atlas CMS 1.png";
import dashboard from "../../images/dashboard.png";
import frontend from "../../images/frontend.png";
import workflow from "../../images/workflow.png";
import imageManagement from "../../images/image-management.png";
import comment from "../../images/comment.jpg";
import { FaHistory, FaComments } from "react-icons/fa";
import { TiFlowSwitch } from "react-icons/ti";
import { PiListNumbersBold } from "react-icons/pi";
import { AiOutlineSchedule } from "react-icons/ai";

const AtlasCms = () => {
  return (
    <div>
      <ScrollToTop></ScrollToTop>
      <Helmet>
        <title>Atlas Cms - Ideahub</title>
      </Helmet>
      <div className="atlas-cms-details">
        <div className="Breadcrumb">
          <div>
            <h1>Atlas CMS</h1>
            <p>
              <Link to="/#products">Products / </Link>Atlas CMS
            </p>
          </div>
        </div>

        <div className="description-atlas mb-5">
          <div className="container">
            <span className="header-text-atlas-cms fw-bold">
              Do Your Best With Atlas CMS
            </span>
            <div className="row align-items-center">
              <div className="col-md-4">
                <img alt="ideahub" src={cmspic}></img>
              </div>
              <div className="col-md-8">
                <span className="sub-header-text-atlas-cms lh-sm">
                  Whether you're writing, Atlas is easy to operate and an easy
                  way to publish your content. .{" "}
                </span>
                <p className="mt-2">
                  The most versatile CMS (Content Management System) for future
                  market adaptation. With ATLAS' futuristic capabilities, you
                  may ease and look forward to the future.
                </p>
              </div>
            </div>

            <div className="row align-items-center mt-5">
              <div className="col-12 col-lg-7">
                <h3>Features:</h3>
                <ul className="mt-3">
                  <li className="fs-5">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        alt="ideahub"
                        src={liIcon}
                        className="me-3 list-icon"
                      ></img>
                      <p className="">
                        Responsive design for providing the most mobile-friendly
                        experience to post updates on the go.
                      </p>
                    </div>
                  </li>
                  <li className="fs-5">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        alt="ideahub"
                        src={liIcon}
                        className="me-3 list-icon"
                      ></img>
                      <p className="">
                        Compatible with modern storytelling formats like live
                        blogs and web stories.
                      </p>
                    </div>
                  </li>
                  <li className="fs-5">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        alt="ideahub"
                        src={liIcon}
                        className="me-3 list-icon"
                      ></img>
                      <p className="">Built-in AI tag generator tool.</p>
                    </div>
                  </li>
                  <li className="fs-5">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        alt="ideahub"
                        src={liIcon}
                        className="me-3 list-icon"
                      ></img>
                      <p className="">Real time fact checking.</p>
                    </div>
                  </li>
                  <li className="fs-5">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        alt="ideahub"
                        src={liIcon}
                        className="me-3 list-icon"
                      ></img>
                      <p className="">
                        Built-in SEO optimization for faster organic results.
                      </p>
                    </div>
                  </li>
        
                  <li className="fs-5">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        alt="ideahub"
                        src={liIcon}
                        className="me-3 list-icon"
                      ></img>
                      <p className="">
                        Super fast response for boosting the productivity of
                        content management team.
                      </p>
                    </div>
                  </li>
                  <li className="fs-5">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        alt="ideahub"
                        src={liIcon}
                        className="me-3 list-icon"
                      ></img>
                      <p className="">
                        Built to handle medium to large content management team.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-lg-5">
                <img alt="ideahub" className="w-100" src={CMS}></img>
              </div>
            </div>

            <div className="row margin-top-5rem">
              <div className="col-md-12 ">
                <span className="header-text-atlas-cms fw-bold">
                  Why choose Atlas over other CMS?
                </span>
                <p>
                  Systems such as WordPress or other CMS are designed for blogs,
                  requiring many 3rd-party plugins to be made suitable for
                  marketing content. And all those plugins lead to high
                  maintenance, slow speed, and security holes. Ideahub’s Atlas
                  CMS includes everything out-of-the-box to quickly build
                  beautiful, highly visual, fast marketing websites with good
                  SEO. No plugins are required. The user-friendly admin
                  interface and developer-friendly API save your whole teams
                  time.
                </p>
              </div>
            </div>

            <div className="row align-items-center margin-top-5rem">
              <div className="col-12 col-lg-5 mb-5">
                <img alt="ideahub" className="w-100" src={dashboard}></img>
              </div>
              <div className="col-12 col-lg-7">
                <span className="sub-header-text-atlas-cms lh-sm">
                  Control how content is displayed and how data is organised.
                </span>
                <p className="mt-2">
                  Editors love it because they have the freedom and control over
                  how content is displayed and how data is organized with our
                  StreamField.
                </p>
              </div>
            </div>

            {/* <div className="row margin-top-5rem">
              <div className="col-md-6 ">
                <h1>Intuitive content organisation</h1>
                <span className="sub-header-text-atlas-cms lh-sm">
                  A tree structure that makes content easy to find and organise.
                </span>
                <p className="mt-2">
                  The Wagtail administration panel uses a tree structure that
                  makes content easy to find and organise. Whether you have a
                  handful of page types or dozens, the content sidebar offers
                  editors a quick, intuitive way to navigate to the content they
                  need.
                </p>
              </div>

              <div className="col-md-6 ">
                <h1>Customizable page types</h1>
                <span className="sub-header-text-atlas-cms lh-sm">
                  Use Django models to easily create and extend a custom
                  architecture.
                </span>
                <p className="mt-2">
                  Wagtail page types make it easy for developers to create and
                  extend a custom architecture. Whether your team needs a blog
                  post or a case study or a donation page, you can spin it up
                  quickly without a ton of database wrangling.
                </p>
              </div>
            </div> */}

            <div className="row align-items-center margin-top-5rem">
              <span className="header-text-atlas-cms fw-bold">
                Advanced image management
              </span>
              <div className="col-md-6 ">
                <img
                  alt="ideahub"
                  className="w-100"
                  src={imageManagement}
                ></img>
              </div>
              <div className="col-md-6">
                <span className="sub-header-text-atlas-cms lh-sm">
                  Keep your image library organized and under control.{" "}
                </span>
                <p className="mt-2">
                  With the image focal point and custom cropping tools in
                  Wagtail, you have more control over how images are cropped and
                  displayed. The multiple image upload and image deduplication
                  features also help you keep your image library organized and
                  under control.
                </p>
              </div>
            </div>

            <div className="row align-items-center margin-top-5rem">
              <div className="col-12 col-lg-8 d-flex flex-column  ">
                <span className="header-text-atlas-cms fw-bold">
                  Full Frontend freedom
                </span>
                <span className="sub-header-text-atlas-cms">
                  Integrate with any frontend technology of your choice.{" "}
                </span>
                <p className="mt-2">
                  The Atlas CMS offers your designers and frontend developers
                  total freedom in crafting the frontend. Atlas CMS seamlessly
                  integrates with the frontend technology of your preference,
                  allowing you to operate it as a headless CMS with multiple
                  frontends if desired. This flexibility empowers your team to
                  create the ideal frontend experience tailored to your specific
                  needs and preferences.
                </p>
              </div>
              <div className="col-12 col-lg-4 d-flex justify-content-center">
                <img alt="ideahub" className="w-50 " src={frontend}></img>
              </div>
            </div>

            <div className="row align-items-center margin-top-5rem">
              <div className="col-12 col-lg-4 d-flex justify-content-center">
                <img alt="ideahub" className="w-50 " src={workflow}></img>
              </div>
              <div className="col-12 col-lg-8 d-flex flex-column  ">
                <span className="header-text-atlas-cms fw-bold">
                  New storytelling workflows
                </span>
                <span className="sub-header-text-atlas-cms">
                  Simplified Workflows for Content Teams.{" "}
                </span>
                <p className="mt-2">
                  Atlas CMS is your solution for advanced enterprise workflows.
                  It empowers content teams with a comprehensive set of features
                  that simplify the entire publishing process, from the initial
                  draft to thorough copyediting and seamless publication. No
                  matter how complex your content creation needs may be, Atlas
                  CMS ensures that every step is made easier, helping your team
                  produce flawless content effortlessly.
                </p>
              </div>
            </div>

            <div className="row row-gap-5 margin-top-5rem">
              <div className="col-md-6 ">
                <div className="d-flex flex-row column-gap-3 align-items-center">
                  <FaHistory className="mb-3" size={30} color="#5f70ff" />

                  <h3>Site history</h3>
                </div>

                <h2>Complete Visibility into Revision Authors.</h2>
                <p>
                  Utilizing the Site History Feature, you gain complete
                  visibility into content modifications, including user actions
                  and methods. This transparency is easily accessible through
                  the administrative panel or via a backend management command,
                  allowing you to log and audit changes efficiently.
                </p>
              </div>

              <div className="col-md-6 ">
                <div className="d-flex flex-row column-gap-3 align-items-center">
                  <PiListNumbersBold
                    className="mb-3"
                    size={30}
                    color="#5f70ff"
                  />

                  <h3>Customisable workflows</h3>
                </div>

                <h2>
                  Manage Content Moderation, Review, and Publishing Permissions.
                </h2>
                <p>
                  Whether you require a single editing step or multiple ones,
                  Atlas CMS's customizable workflows offer flexibility, enabling
                  you to incorporate as little or as much content review as
                  necessary. Atlas CMS's precise permissions system simplifies
                  the management of individuals who can moderate, review, and
                  publish content, granting you full control over the process.
                </p>
              </div>

              <div className="col-md-6 ">
                <div className="d-flex flex-row column-gap-3 align-items-center">
                  <TiFlowSwitch className="mb-3" size={30} color="#5f70ff" />

                  <h3>Revision rollbacks</h3>
                </div>

                <h2>
                  Revert to the Desired Version for Sharing with the World.
                </h2>
                <p>
                  Editing often involves multiple revisions, and keeping track
                  of changes made from version to version can be challenging.
                  Atlas CMS includes a built-in revision comparison tool that
                  facilitates the process of rolling back to the version you
                  wish to share with the world. This tool simplifies the
                  management of content revisions and ensures you can easily
                  select the desired version for publication.
                </p>
              </div>

              <div className="col-md-6">
                <div className="d-flex flex-row column-gap-3 align-items-center">
                  <AiOutlineSchedule
                    className="mb-3"
                    size={30}
                    color="#5f70ff"
                  />

                  <h3>Scheduling</h3>
                </div>

                <h2>Take total control of your Editorial Calendar.</h2>
                <p>
                  With Atlas CMS, you have the flexibility to schedule posts to
                  go live at your preferred times and set an expiration date on
                  content. This feature ensures that time-limited posts are
                  automatically unpublished when the set date arrives, offering
                  you complete control over your content's publication and
                  visibility..
                </p>
              </div>
            </div>

            <div className="row align-items-center margin-top-5rem">
              <div className="col-12 col-lg-5 ">
                <div className="d-flex flex-row column-gap-4 align-items-center">
                  <FaComments className="mb-3" size={40} color="#5f70ff" />
                  <h3 className="">Commenting and notifications</h3>
                </div>
                <h2 className="sub-header-text-atlas-cms">
                  Editors Can Review and Request Content Edits Within Atlas CMS
                  .{" "}
                </h2>
                <p>
                  The Atlas CMS offers your designers and frontend developers
                  total freedom in crafting the frontend. Atlas CMS seamlessly
                  integrates with the frontend technology of your preference,
                  allowing you to operate it as a headless CMS with multiple
                  frontends if desired. This flexibility empowers your team to
                  create the ideal frontend experience tailored to your specific
                  needs and preferences.
                </p>
              </div>
              <div className="col-12 col-lg-7 d-flex justify-content-center">
                <img alt="ideahub" className="w-100 " src={comment}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtlasCms;
