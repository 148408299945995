import React from "react";
import "./BiDashboard.m.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import biDashboardCover from "../../images/Bi-Dash.png";
import biDashboardCover2 from "../../images/bi-2nd.png";
import biDashboardCover3 from "../../images/Bi-offer.png";
import liIcon from "../../images/li-icon.png";
import faqCover from "../../images/FAQ.png";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
const BiDashboard = () => {
  return (
    <div className=" biDashboard-details">
      <ScrollToTop />
      <Helmet>
        <title>BI Dashboard-Ideahub</title>
      </Helmet>
      <div className="main-section d-flex justify-content-center align-items-center ">
        <div className="container">
          <div className="row align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 ">
              <div>
                <h1 className="text-uppercase fw-bold ">BI Dashboard</h1>
                <p className=" fs-4">
                  Transform Data into Insights with our BI Dashboard Solutions
                </p>
                <h4 className=" d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
                  <Link to="/contact-us" className="text-decoration-none text-reset">Talk With Us</Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={biDashboardCover} alt="" className="w-75 " />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center g-5 mx-md-5 ">
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src={biDashboardCover2}
              alt=""
              className="w-75 h-50 d-flex justify-content-center"
            />
          </div>
          <div className="col-lg-6 ">
            <h2 className="text-uppercase fw-bold ">
              Experience and Expertise:
            </h2>
            <p className=" fs-5">
              Unlock insights and drive decisions with our BI Dashboard service.
              With expertise in creating custom dashboards using Power BI and
              QlikView/QlikSense, we help you transform raw data into actionable
              insights. Visualize trends, analyze performance, and make informed
              choices that propel your business forward.
            </p>
          </div>
        </div>

        <div className="row align-items-center mx-md-5">
          <div className="col-md-6">
            <h2 className="text-uppercase fw-bold ">Services Offered:</h2>
            <ul className="mt-3">
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Custom BI Dashboard Development
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Power BI Integration and Implementation
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    QlikView/QlikSense Development and Deployment
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    DevOps Solutions and Support
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
              src={biDashboardCover3}
              alt=""
              className="w-75 d-flex justify-content-center"
            />
          </div>
        </div>

        <div className="row g-5 mt-2">
          <div className="col-md-5 d-flex justify-content-center">
            <img src={faqCover} alt="" className="w-75" />
          </div>
          <div className="col-md-7 ">
            <h2 className=" fw-bold mb-3">Frequently Asked Questions:</h2>
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle={
                  <strong> How can a BI Dashboard benefit my business?</strong>
                }
              >
                A BI Dashboard empowers your business by turning complex data
                into understandable visuals. It enables you to quickly identify
                patterns, trends, and anomalies, aiding strategic
                decision-making, optimizing processes, and improving overall
                efficiency..
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle={
                  <strong>
                    How do you transform raw data into actionable insights
                    through BI Dashboards?
                  </strong>
                }
              >
                Our process involves data collection, cleansing, transformation,
                and visualization. We work closely with you to understand your
                key performance indicators (KPIs) and business objectives,
                crafting dashboards that present data in a way that helps you
                extract valuable insights.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle={
                  <strong>
                    What types of insights can be visualized using BI
                    Dashboards?
                  </strong>
                }
              >
                You can visualize a wide range of insights, such as sales
                performance, customer behavior, financial trends, operational
                efficiency, and more. BI Dashboards help you gain a holistic
                view of your business's health and performance.
              </MDBAccordionItem>
            </MDBAccordion>
          </div>
        </div>

        {/* <div className="row mt-5 align-items-center g-5 mx-md-5 ">
    <div className="col-lg-6 d-flex justify-content-center">
      <img src={contentImage4} alt="" className="w-75 " />
    </div>
    <div className="col-lg-6 ">
      <div>
        <h2 className="text-uppercase fw-bold ">Discover</h2>
        <p className=" fs-5">
          Content is the life of your IPTV/OTT platform. Once you have the
          content, you push it to our transcoder.
        </p>
        <p className=" fs-5">
          Content is the life of your IPTV/OTT platform. Once you have the
          content, you push it to our transcoder.
        </p>
      </div>
    </div>
  </div>
  <div className="section-2 mb-5 ">
    <div>
      <img src={contentImage5} className="w-100" />
    </div>
  </div> */}
      </div>
    </div>
  );
};

export default BiDashboard;
