import React from "react";
import "./CustomDev.m.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import customDevCover from "../../images/Custom-Dev-1st-Vector.png";
import customDevCover2 from "../../images/Custom-Deve-2nd-vector.png";
import liIcon from "../../images/li-icon.png";
import faqCover from "../../images/FAQ.png";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const CustomDev = () => {
  return (
    <div className=" custom-development-details">
      <ScrollToTop />
      <Helmet>
        <title>Custom Development-Ideahub</title>
      </Helmet>
      <div className="main-section d-flex justify-content-center align-items-center ">
        <div className="container">
          <div className="row align-items-center g-5 mx-md-5 ">
            <div className="col-lg-6 ">
              <div>
                <h1 className="text-uppercase fw-bold ">Custom Development</h1>
                <p className=" fs-4">
                  Elevate Your Business with Custom Software Solutions from
                  ideahub
                </p>
                <h4 className=" d-inline-block header-text px-3 py-3 rounded-2 fw-bold text-decoration-none">
                  <Link
                    to="/contact-us"
                    className="text-decoration-none text-reset"
                  >
                    Talk With Us
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={customDevCover} alt="" className="w-75 " />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center g-5 mx-md-5 ">
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src={customDevCover2}
              alt=""
              className="w-100 d-flex justify-content-center"
            />
          </div>
          <div className="col-lg-6 ">
            <h2 className="text-uppercase fw-bold ">
              Experience and Expertise:
            </h2>
            <p className=" fs-5">
              With a rich history of 15+ years in .NET and C#, as well as
              expertise in MERN stack and headless technology, We have a team of
              skilled developers who understand your business needs and deliver
              high-quality custom solutions. From the initial concept to the
              final product, we support you throughout the entire process of
              developing, testing, and updating your business applications.
            </p>
          </div>
        </div>

        <div className="row list-section mx-md-5">
          <div className="col-md-6">
            <h2 className="text-uppercase fw-bold ">Tech Expertise:</h2>
            <ul className="mt-3">
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    15+ Years of Experience in .NET & C#
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">MERN Stack</p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">Django/Wagtail</p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">Headless Technology</p>
                </div>
              </li>

              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">Flutter and Xamarin</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <h2 className="text-uppercase fw-bold ">Services Offered:</h2>
            <ul className="mt-3">
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Custom Software Development
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Cross-Platform Mobile App Development
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">
                    Corporate Website Design and Development
                  </p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">Technology Modernization</p>
                </div>
              </li>
              <li className="fs-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    alt="ideahub"
                    src={liIcon}
                    className="me-3 list-icon"
                  ></img>
                  <p className="list-description">UI/UX</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="row g-5 faq-section">
          <div className="col-md-5 d-flex justify-content-center">
            <img src={faqCover} alt="" className="w-75 " />
          </div>
          <div className="col-md-7 ">
            <h2 className=" fw-bold mb-3">Frequently Asked Questions:</h2>
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle={<strong>What is Custom Development?</strong>}
              >
                Custom Development refers to the process of creating software
                applications, websites, and mobile apps tailored to specific
                business needs. Unlike off-the-shelf solutions, custom
                development offers personalized and unique solutions that align
                precisely with your requirements.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle={
                  <strong>
                    Can you provide examples of projects you've worked on using
                    .NET and C#?
                  </strong>
                }
              >
                Certainly, we've successfully delivered projects ranging from
                enterprise-level applications to web services using .NET and C#.
                Our portfolio showcases diverse solutions, including e-commerce
                platforms, CRM systems, and more.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle={
                  <strong>
                    Can you handle cross-platform mobile app development?
                  </strong>
                }
              >
                Absolutely, we have extensive experience in creating
                cross-platform mobile apps that work seamlessly across iOS and
                Android devices. This approach ensures a wider reach and a
                consistent user experience.
              </MDBAccordionItem>
            </MDBAccordion>
          </div>
        </div>

        {/* <div className="row mt-5 align-items-center g-5 mx-md-5 ">
        <div className="col-lg-6 d-flex justify-content-center">
          <img src={contentImage4} alt="" className="w-75 " />
        </div>
        <div className="col-lg-6 ">
          <div>
            <h2 className="text-uppercase fw-bold ">Discover</h2>
            <p className=" fs-5">
              Content is the life of your IPTV/OTT platform. Once you have the
              content, you push it to our transcoder.
            </p>
            <p className=" fs-5">
              Content is the life of your IPTV/OTT platform. Once you have the
              content, you push it to our transcoder.
            </p>
          </div>
        </div>
      </div>
      <div className="section-2 mb-5 ">
        <div>
          <img src={contentImage5} className="w-100" />
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default CustomDev;
